<template>
  <div class="create-competition-control">
    <Button
      @click="showModal"
      :icon="'plus'"
      :variant="'primary'"
    >
      Create competition
    </Button>
    <teleport to="body">
      <CreateCompetitionModal
        v-if="isModalVisible || competitionToUpdate"
        :competition-to-update="competitionToUpdate"
        @on-close="hideModal"
        @on-submit="onSubmit"
      />
    </teleport>
  </div>
</template>

<script>
import { ref } from 'vue';
import Button from '@/components/common/Button';
import CreateCompetitionModal from './CreateCompetitionModal';

export default {
  emits: ['onModalClose', 'onSubmit'],
  components: {
    Button,
    CreateCompetitionModal,
  },
  props: {
    competitionToUpdate: {
      type: Object,
      default: null,
    },
  },
  setup(_, { emit }) {
    const isModalVisible = ref(false);

    const showModal = () => {
      isModalVisible.value = true;
    };
    const hideModal = () => {
      isModalVisible.value = false;
      emit('onModalClose');
    };
    const onSubmit = () => {
      emit('onSubmit');
      emit('onModalClose');
    };

    return {
      isModalVisible,
      showModal,
      hideModal,
      onSubmit,
    };
  },
};
</script>

<style lang="scss">
.create-competition-control {
  .button .icon svg path {
    stroke: #fff;
  }
}
</style>
