<template>
  <div class="create-competition-modal">
    <div class="create-competition-modal__frame">
      <div class="create-competition-modal__header">
        <div class="create-competition-modal__heading">
          Create competition
        </div>
      </div>
      <div
        class="create-competition-modal__form"
      >
        <div class="create-competition-modal__form-group">
          <div class="create-competition-modal__form-label">
            Competiton name
          </div>
          <TextInput
            :model-value="competitionName"
            @update:modelValue="onCompetitionNameChange"
            placeholder="Competiton name"
            small
          />
        </div>
        <div class="create-competition-modal__form-group">
          <div class="create-competition-modal__form-label">
            Age category
          </div>
          <Select
            variant="secondary"
            :model-value="selectedAgeCategory"
            :options="availableAgeCategories"
            @update:model-value="onSelctedAgeCategoryChange"
            placeholder="U21"
            omit-prefix
          />
        </div>
        <div class="create-competition-modal__form-group">
          <div class="create-competition-modal__form-label">
            Region
          </div>
          <SearchSelect
            :model-value="selectedRegionId"
            :options="availableRegions"
            :is-loading="isLoadingRegions"
            @update:model-value="setSelectedRegionId"
            @update:options="loadRegions"
            placeholder="United States of America"
            :auto-focus="true"
          />
        </div>
        <div class="create-competition-modal__form-group">
          <div class="create-competition-modal__form-label">
            Gender
          </div>
          <Select
            variant="secondary"
            :model-value="selectedGender"
            :options="availableGenders"
            @update:model-value="setSelectedGender"
            placeholder="Male"
            omit-prefix
          />
        </div>
        <div class="create-competition-modal__form-group">
          <div class="create-competition-modal__form-label">
            Ruleset
          </div>
          <Select
            variant="secondary"
            :model-value="selectedRuleset"
            :options="availableRulesets"
            @update:model-value="setSelectedRuleset"
            placeholder="National Basketball Championship"
            omit-prefix
          />
        </div>
      </div>
      <div class="create-competition-modal__footer">
        <Button
          variant="tertiary"
          @click="closeModal"
        >
          Dismiss
        </Button>
        <Button
          variant="primary"
          :disabled="!isAllowedToSubmit || isProcessing"
          :loading="isProcessing"
          @click="submitEvent"
        >
          {{ isCompetitionUpdate ? 'Update' : 'Create' }} competition
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  map,
  isEqual,
} from 'lodash';
import {
  ref,
  computed,
  onMounted,
} from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useRegionsQuery } from '@/services/api/graphql';
import Button from '@/components/common/Button';
import Select from '@/components/common/Select';
import SearchSelect from '@/components/common/SearchSelect';
import TextInput from '@/components/common/TextInput';
import { createNewCompetition, updateCompetition } from '@/services/api/charon';
import sportIds from '@/services/helpers/sports';

const {
  BASKETBALL_ID,
  FOOTBALL_ID,
  HOCKEY_ID,
  BASEBALL_ID,
} = sportIds;

export default {
  components: {
    Button,
    Select,
    SearchSelect,
    TextInput,
  },
  props: {
    initialSportId: {
      type: String,
      default: '',
    },
    initialCompetitionId: {
      type: String,
      default: '',
    },
    competitionToUpdate: {
      type: Object,
      default: null,
    },
  },
  emits: {
    onClose: {},
    onSubmit: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const isCompetitionUpdate = computed(() => !!props.competitionToUpdate);
    const competitionToUpdateInitialState = ref(null);
    const selectedSportId = computed(() => route.query.sport ?? store.getters.manualEditSelectedSport);
    const competitionName = ref('');
    const onCompetitionNameChange = (newCompetitionName) => {
      competitionName.value = newCompetitionName;
    };

    const isProcessing = ref(false);

    const availableAgeCategories = computed(() => [
      {
        value: 'U18',
        label: 'U18',
      }, {
        value: 'U21',
        label: 'U21',
      }, {
        value: 'PRO',
        label: 'Pro',
      },
    ]);
    const selectedAgeCategory = ref('');
    const onSelctedAgeCategoryChange = (newSelectedAgeCategory) => {
      selectedAgeCategory.value = newSelectedAgeCategory;
    };

    const availableGenders = computed(() => [
      {
        value: 'MALE',
        label: 'Male',
      }, {
        value: 'FEMALE',
        label: 'Female',
      }, {
        value: 'MIXED',
        label: 'Mixed',
      },
    ]);
    const selectedGender = ref('');
    const setSelectedGender = (newSelectedGender) => {
      selectedGender.value = newSelectedGender;
    };

    const availableRulesets = computed(() => {
      switch (selectedSportId.value) {
      case BASKETBALL_ID:
        return [
          {
            value: 'NBA',
            label: 'NBA',
          },
          {
            value: 'FIBA',
            label: 'FIBA',
          },
          {
            value: 'NCAA',
            label: 'NCAA',
          },
        ];
      case FOOTBALL_ID:
        return [
          {
            value: 'NCAA/CFL',
            label: 'NCAA/CFL',
          },
          {
            value: 'NFL',
            label: 'NFL',
          },
        ];
      case HOCKEY_ID:
        return [
          {
            value: 'NHL',
            label: 'NHL',
          },
        ];
      case BASEBALL_ID:
        return [
          {
            value: 'MLB',
            label: 'MLB',
          },
        ];
      default:
        return [];
      }
    });
    const selectedRuleset = ref('');
    const setSelectedRuleset = (newSelectedRuleset) => {
      selectedRuleset.value = newSelectedRuleset;
    };

    const availableRegions = ref([]);
    const isLoadingRegions = ref(false);
    const selectedRegionId = ref(null);
    const loadRegions = (searchQuery) => {
      isLoadingRegions.value = true;
      useRegionsQuery({
        queryOptions: {
          filter: {
            regionName: {
              includesInsensitive: searchQuery || '',
            },
          },
        },
      })
        .then((response) => {
          availableRegions.value = map(response.data?.regions?.nodes || [], (region) => ({
            value: region.regionId,
            label: region.regionName,
          }));
        })
        .finally(() => {
          isLoadingRegions.value = false;
        });
    };
    const setSelectedRegionId = (newSelectedRegionId) => {
      selectedRegionId.value = newSelectedRegionId;
    };

    const createPayloadObject = () => ({
      age: selectedAgeCategory.value,
      defaultRules: selectedRuleset.value,
      gender: selectedGender.value,
      name: competitionName.value,
      regionId: selectedRegionId.value?.value,
      sportId: selectedSportId.value,
    });

    const isAllowedToSubmit = computed(() => {
      if (isCompetitionUpdate.value) {
        return !isEqual(competitionToUpdateInitialState.value, createPayloadObject());
      }
      const isValidCompetitionName = !!competitionName.value?.length;
      const isValidSelectedAgeCategory = !!selectedAgeCategory.value?.length;
      const isValidSelectedRegion = !!selectedRegionId.value?.value;
      const isValidSelectedGender = !!selectedGender.value?.length;
      const isValidSelectedRuleset = !!selectedRuleset.value?.length;
      return isValidCompetitionName
        && isValidSelectedAgeCategory
        && isValidSelectedRegion
        && isValidSelectedGender
        && isValidSelectedRuleset;
    });

    const submitEvent = () => {
      isProcessing.value = true;
      const payload = createPayloadObject();
      if (isCompetitionUpdate.value) {
        payload.competitionId = props.competitionToUpdate.competitionId;
      }

      const action = isCompetitionUpdate.value ? updateCompetition : createNewCompetition;

      action(payload)
        .then(() => {
          store.dispatch('addNotification', {
            message: `Competition successfully ${isCompetitionUpdate.value ? 'updated' : 'created'}!`,
            type: 'success',
            duration: 5000,
          });
          emit('onSubmit');
        })
        .catch(() => {
          store.dispatch('addNotification', {
            message: `Failed to ${isCompetitionUpdate.value ? 'update' : 'create'} competition`,
            type: 'error',
            duration: 5000,
          });
        })
        .finally(() => {
          isProcessing.value = false;
          emit('onClose');
        });
    };

    const closeModal = () => {
      emit('onClose');
    };

    onMounted(async () => {
      if (props.competitionToUpdate) {
        competitionName.value = props.competitionToUpdate.competitionName;
        selectedAgeCategory.value = props.competitionToUpdate.ageCategory;
        selectedGender.value = props.competitionToUpdate.gender;
        selectedRuleset.value = props.competitionToUpdate.defaultRules;
        selectedRegionId.value = props.competitionToUpdate.regionId ? {
          value: props.competitionToUpdate.regionId,
          label: props.competitionToUpdate.regionName,
        } : null;
        competitionToUpdateInitialState.value = createPayloadObject();
      }
    });

    return {
      competitionName,
      onCompetitionNameChange,
      availableAgeCategories,
      selectedAgeCategory,
      onSelctedAgeCategoryChange,
      availableRegions,
      selectedRegionId,
      setSelectedRegionId,
      loadRegions,
      isLoadingRegions,
      availableGenders,
      selectedGender,
      setSelectedGender,
      availableRulesets,
      selectedRuleset,
      setSelectedRuleset,
      isProcessing,
      isAllowedToSubmit,
      closeModal,
      submitEvent,
      isCompetitionUpdate,
    };
  },
};
</script>

<style lang="scss">
.create-competition-modal {
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $modalZIndex;
  pointer-events: all;

  .select {
    .select-dropdown {
      max-height: 200px;

      .select-dropdown-item {
        flex-shrink: 0;
      }
    }
  }

  .date-picker {
    height: 32px;
    max-height: 32px;
    min-height: 32px;
  }

  &__frame {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 6px;
    cursor: default;
    width: 480px;
  }

  &__header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 4px;
    padding: 0 16px;
    height: 74px;
    border-bottom: 1px solid #F0F0F0;

    .icon {
      stroke: #000;
      cursor: pointer;
    }
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 264px;
  }

  &__form {
    display: grid;
    grid-template-columns: calc(65% - 8px) calc(35% - 8px);
    grid-template-rows: repeat(3, auto);
    padding: 16px;
    gap: 16px;

    &-group {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }

    &-label {
      font-family: 'Rubik', sans-serif;
      font-weight: 600;
      font-size: 10px;
      text-transform: uppercase;
      line-height: 11.85px;
      color: #6D6D6D;
    }

    &-error {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 10px;
      color: $error500;
      margin-top: 4px;

      &-icon {
        flex-shrink: 0;
        width: 12px;
        height: 12px;
        border-radius: 100%;
        overflow: hidden;

        svg {
          background-color: $error500;
          fill: #fff;
          width: 100%;
          height: 100%;
        }
      }

      &-message {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
    height: 64px;
    padding: 0 16px;
    border-top: 1px solid #F0F0F0;
  }

  &__heading {
    font-size: 15.72px;
    font-family: 'Poppins';
    font-weight: 600;
  }

  &__text {
    font-size: 12px;
    line-height: 14px;
    color: $gray700;
  }
}
</style>
