<template>
  <Modal
    :title="`Delete competition?`"
    :visible="confirmModalActive"
    class="confirm-competition-delete-modal"
    @close="onModalClose"
  >
    <div class="modal-text">
      <p>If you proceed, the competition will be permanently deleted.</p>
    </div>
    <div class="modal-actions">
      <Button
        @click="onModalClose"
        variant="tertiary"
      >
        Cancel
      </Button>
      <Button
        @click="onDeleteCompetitionConfirm"
        variant="danger"
      >
        Delete competition
      </Button>
    </div>
  </Modal>
</template>

<script>
import Button from '@/components/common/Button';
import Modal from '@/components/common/Modal';

export default {
  emits: ['onModalClose', 'onDeleteCompetitionConfirm'],
  props: {
    confirmModalActive: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Button,
    Modal,
  },
  setup(_, { emit }) {
    const onModalClose = () => {
      emit('onModalClose');
    };
    const onDeleteCompetitionConfirm = () => {
      emit('onDeleteCompetitionConfirm');
    };
    return {
      onModalClose,
      onDeleteCompetitionConfirm,
    };
  },
};
</script>

<style lang="scss">
  .confirm-competition-delete-modal {
    .modal__dialog {
      max-width: 360px !important;
      .modal__header {
        padding: 16px;
        border-bottom: 1px solid $gray400;
        .modal__title {
          padding: 0;
        }
      }
      .modal__content {
        .modal-text {
          font-size: 14px;
          color: $gray800;
          padding: 16px;
        }
       .modal-actions {
          padding: 16px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 8px;
          border-top: 1px solid $gray400;
        }
      }
    }
  }
</style>
